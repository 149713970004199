import Vue from 'vue'
import VueRouter from 'vue-router'

import versionTood from '@/utils/version'

// Vue.use(VueRouter)
Vue.use(VueRouter)
const originalPush = VueRouter.prototype.push;
 
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}


const routes = [
  {
    path: '/',
    redirect: '/home/index',
  },
  {
    path: '/home',
    name: 'home',
    component: () =>import ('../views/Home.vue'),
    children: [
      {
        path: 'index',
        name: 'index',
        component: () => import('../views/index/index.vue')
      },
      {
        path: 'oddJobs',
        name: 'oddJobs',
        component: () => import('../views/oddJobs/oddJobs.vue')
      },
      {
        path: 'recruitment',
        name: 'recruitment',
        component: () => import('../views/recruitment/recruitment.vue')
      }, 
      {
        path: 'jobFair',
        name: 'jobFair',
        component: () => import('../views/jobFair/jobFair.vue')
      },
      {
        path: 'payroll',
        name: 'payroll',
        component: () => import('../views/payroll/payroll.vue')
      },
      {
        path: 'AboutUs',
        name: 'AboutUs',
        component: () => import('../views/AboutUs/AboutUs.vue')
      }, 
      {
        path: 'information',
        name: 'information',
        component: () => import('../views/information/index.vue')
      }
      
    ]
  }
  
]


const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

router.beforeEach((to, from, next) => {
  // console.log('beforeEach', to, from) // 打印当前路由的信息 可以是一个对象或者一个数
  //判断当前代码版本是否与服务器中代码版本一致，如不一致则刷新页面获取最新
  versionTood.isNewVersion();
  next()
})


export default router
