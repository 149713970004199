const baseSize = 16
const isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)
function setRem() {
  if (isMobile) {
    const scale = document.documentElement.clientWidth / 750
    document.documentElement.style.fontSize = baseSize * scale + 'px'
  } else {
    const scale = document.documentElement.clientWidth / 1920
    document.documentElement.style.fontSize = baseSize * scale + 'px'
  }
}
setRem()
window.onresize = function () {
  setRem()
}
