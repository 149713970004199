import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'

Vue.config.productionTip = false

import './utils/rem.js'


 
function px2rem(px){
  if (/%/ig.test(px)) { // 有百分号%，特殊处理，表述pc是一个有百分号的数，比如：90%
    return px
  } else {
    return (parseFloat(px) / 16) + 'rem'
  }
}
Vue.prototype.$px2rem = px2rem // 放到全局


import { Button, Select,Tabs,TabPane,Menu,MenuItem,Submenu,Carousel,CarouselItem, } from 'element-ui';

Vue.use(Button)
Vue.use(Select)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Menu)
Vue.use(MenuItem)
Vue.use(Submenu)
Vue.use(Carousel)
Vue.use(CarouselItem)


// vantUi
import { Icon,DropdownMenu, DropdownItem } from 'vant';
Vue.use(Icon).use(DropdownMenu).use(DropdownItem);

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
